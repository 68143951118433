<template>
  <div
    class="container"
    v-click-outside="closeDropdownList"
  >
    <div
      v-if="!value"
      class="js-value-container"
      @click="toggleDropdownList"
    >
      <base-input-text
        :label="label"
        :input-id="inputId"
        :ref="inputId"
        :input-name="inputName"
        v-model.trim="inputValue"
        :disabled="disabled"
        :loading="loading"
        :required="required"
        :error="error"
        :error-message="errorMessage"
        :success="!error && success"
        @focus="hasFocus = true"
        @focusout="hasFocus = false"
        @keydown="handleInputKeys"
        :placeholder="`- ${placeholder} -`"
      >
        <template #suffix>
          <ic-chevron :size="22" />
        </template>
      </base-input-text>
    </div>

    <div
      v-else
      @click="resetSelection"
      class="w-full cursor-pointer js-value-container"
    >
      <base-input-text
        :label="label"
        :required="required"
        :input-id="inputId"
        :input-name="inputName"
        :value="value ? itemLabel(value) : null"
        :disabled="disabled"
        :loading="loading"
        :error="error"
        :error-message="errorMessage"
        :success="!error && success"
        @focus="hasFocus = true"
        @focusout="hasFocus = false"
      >
        <template #suffix>
          <ic-chevron
            :size="22"
            class="chevron"
            :class="{ 'text-bb-mid-grey': hasFocus }"
          />
        </template>
      </base-input-text>
    </div>

    <div
      class="dropdown-list border"
      ref="dropdown-list"
      :id="`${inputId}-dropdown-list`"
      v-show="displayDropdownList"
    >
      <div
        v-for="item in items"
        v-show="itemVisible(item, inputValue)"
        :key="itemKey ? itemKey(item) : itemLabel(item)"
        :id="itemKey ? itemKey(item) : itemLabel(item)"
        @click="selectItem(item)"
        :data-item="JSON.stringify(item)"
        class="dropdown-item"
      >
        {{ itemLabel(item) }}
      </div>
      <div
        v-if="noFilterMatch"
        class="dropdown-item text-bb-mid-grey italic"
      >
        {{ noMatchFeedback }}
      </div>
    </div>
  </div>
</template>
<script>
import BaseInputText from '@/components/input/base/BaseInputText'
import IcChevron from 'vue-material-design-icons/ChevronDown'

const ARROW_DOWN = 40
const ARROW_UP = 38
const TAB = 9
const ENTER = 13
const ESC = 27

/**
 * Note about focus list item:
 * Using index for focusing directly in html doesn't work because the values doesn't get reset. That's why we set focus
 * directly on the element.
 */
export default {
  name: 'base-filter-picker',
  components: { IcChevron, BaseInputText },
  props: {
    value: {
      default: null,
    },
    inputId: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: [String, null],
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    itemKey: {
      type: Function,
    },
    filter: {
      type: Function,
      required: true,
    },
    itemLabel: {
      type: Function,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    noMatchFeedback: {
      type: String,
      default: 'No match',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      hasFocus: false,
      inputValue: null,
      showAll: false,
      focusIndex: null,
    }
  },
  watch: {
    disabled(val) {
      if (!val) {
        this.showAll = false
      }
    },
    loading(val) {
      if (val) {
        this.showAll = false
      }
    },
    displayDropdownList(val) {
      if (!val) {
        this.focusIndex = null
      }
      this.$emit(val ? 'focus' : 'focusout')
    },
    inputValue(_) {
      this.focusIndex = null
    },
    hasFocus(val) {
      this.$emit(val ? 'focus' : 'focusout')
    },
    focusIndex(val, oldVal) {
      // remove focus from previous item, add focus to current item

      const list = document.getElementById(`${this.inputId}-dropdown-list`)

      if (oldVal !== null) {
        list.children[oldVal].classList.remove('focus')
      }

      if (val !== null) {
        list.children[val].classList.add('focus')
      }
    },
  },
  computed: {
    displayDropdownList() {
      return this.showAll || !!this.inputValue
    },
    visibleItemsCount() {
      if (this.showAll && !this.inputValue) return this.items.length
      return this.items.filter(item => this.filter(item, this.inputValue)).length
    },
    noFilterMatch() {
      return this.inputValue && this.visibleItemsCount === 0
    },
  },
  methods: {
    itemVisible(item) {
      if (this.inputValue) {
        return this.filter(item, this.inputValue)
      }
      return this.showAll
    },
    selectItem(item) {
      this.inputValue = null
      this.showAll = false
      this.$emit('input', item)
    },
    resetSelection() {
      if (!this.disabled && !this.loading) {
        this.showAll = true
        this.$nextTick(() => document.getElementById(this.inputId).focus())
        this.$emit('input', null)
      }
    },
    toggleDropdownList() {
      if (!this.disabled && !this.loading) {
        this.showAll = !this.showAll
      }
    },
    closeDropdownList() {
      this.inputValue = null
      this.showAll = false
    },
    getItemByFocusIndex() {
      if (this.focusIndex === null) {
        return null
      }

      const list = document.getElementById(`${this.inputId}-dropdown-list`)
      const listItem = list.children[this.focusIndex]

      return listItem ? JSON.parse(listItem.dataset.item) : null
    },
    handleInputKeys(e) {
      switch (e.keyCode) {
        case TAB:
        case ESC:
          this.showAll = false
          break

        case ARROW_DOWN:
          this.handleArrowDown(e)
          break

        case ARROW_UP:
          this.handleArrowUp(e)
          break

        case ENTER:
          e.preventDefault()
          this.selectItem(this.getItemByFocusIndex())
          break
      }
    },
    handleArrowUp(e) {
      e.preventDefault()

      if (!this.showAll && !this.inputValue) {
        // If someone starts using arrows without any input
        this.showAll = true
      }
      // Next tick because showAll must be set before continuing
      this.$nextTick(() => {
        if (this.noFilterMatch) return

        if (this.focusIndex === null || this.focusIndex === 0) {
          // If no focus exist or first item has focs then start with last item
          this.focusIndex = this.visibleItemsCount - 1
        } else {
          // Give previous item focus
          this.focusIndex--
        }
      })
    },
    handleArrowDown(e) {
      e.preventDefault()
      if (!this.showAll && !this.inputValue) {
        // If someone starts using arrows without any input
        this.showAll = true
      }
      // Next tick because showAll must be set before continuing
      this.$nextTick(() => {
        if (this.noFilterMatch) return

        if (this.focusIndex === null || this.focusIndex === this.visibleItemsCount - 1) {
          // If no focus exist or last item has focs then start with first item
          this.focusIndex = 0
        } else {
          // Give next item focus
          this.focusIndex++
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
$item-height: 38px;
$list-height: calc(5 * 38px);

.container {
  position: relative;
  width: 100%;
  min-width: 100%;
  margin: 0;
  display: inline-block;
}

.dropdown-list {
  @apply absolute w-full bg-white rounded border-bb-cool-grey shadow-md;
  top: 64px;
  border-radius: 8px;
  max-height: 192px;
  overflow-y: auto;
  z-index: 1;
}

.dropdown-input {
  width: 100%;
  padding: 10px 16px;
  background: #edf2f7;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
  min-height: $list-height;
  height: $list-height;
  max-height: $list-height;

  &:focus {
    background: #fff;
    border-color: #e2e8f0;
  }
}

.dropdown-item {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
  min-height: $item-height;
  height: $item-height;
  max-height: $item-height;

  &:hover {
    @apply bg-bb-cool-grey;
  }

  &.focus {
    @apply bg-bb-pale-grey;
  }
}
</style>
